// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"


const theme = extendTheme({
    fonts: {
        body: "'Open Sans', sans-serif",
        heading: "'Martel', serif",
      },
    colors: {
        customWhite: "#FCFCFC",
        customPink: "#F7567C",
        customYellow: "#FFFAE3",
        customGreen: "#99E1D9",
        customDark: "#5D576B",

        // red.400
        // orange.100
        // teal.200
        // cyan.600
        // grey.100


        // https://www.happyhues.co/palettes/9

        // greybackground #eff0f3
        // headline black #0d0d0d
        // paragraph black #2a2a2a
        // orange button #ff8e3c
        // pinkish #d9376e
        // white #fffffe
        brandWhite: '#fffffe',

        brandGrey:
        {
          50: '#f0f1f4',
          100: '#d5d6d9',
          200: '#b9bbc0',
          300: '#9c9fa9',
          400: '#808493',
          500: '#666b7a',
          600: '#50535e',
          700: '#393b43',
          800: '#232428',
          900: '#0b0c0f',
        },
        brandOrange:
        {
          50: '#ffeedc',
          100: '#ffd2af',
          200: '#ffb57f',
          300: '#ff984d',
          400: '#fe7a1c',
          500: '#e56103',
          600: '#b34b00',
          700: '#813600',
          800: '#4f1f00',
          900: '#200800',
        },
        brandPink:
        {
          50: '#ffe6f0',
          100: '#f8bdd0',
          200: '#ed93b1',
          300: '#e46892',
          400: '#da3e73',
          500: '#c1255a',
          600: '#971a46',
          700: '#6d1132',
          800: '#43071e',
          900: '#1c000b',
        },
        brandBlack:
        {
          50: '#f2f2f2',
          100: '#d9d9d9',
          200: '#bfbfbf',
          300: '#a6a6a6',
          400: '#8c8c8c',
          500: '#737373',
          600: '#595959',
          700: '#404040',
          800: '#262626',
          900: '#0d0d0d',
        }

        // customWhite: {
        //     100: "#FCFCFC"
        // },
        // customWhite: {
        //     100: "#FCFCFC"
        // },
        // customWhite: {
        //     100: "#FCFCFC"
        // }
    //   brand: {
    //     100: "#f7fafc",
    //     200: "#F7567C",
    //   },
    },
  })

export default theme